import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/base.css'
import VueI18n from 'vue-i18n'
import cn from '@/components/common/lang/cn'
import en from '@/components/common/lang/en'
import ru from '@/components/common/lang/ru'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: window.sessionStorage.getItem('language') || 'en',
  messages: { cn, en, ru }
})

Vue.config.productionTip = false

const initApp = async () => {
  window.vue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}
initApp()
window.onload = function () {
  document.addEventListener('gesturestart', function (e) {
    e.preventDefault()
  })
  // 以上是禁止双指缩放
  // 以下是禁止双击缩放
  document.addEventListener('touchstart', function (e) {
    if (e.touches.length > 1) {
      e.preventDefault()
    }
  })
  let endTouch = 0
  document.addEventListener(
    'touchend',
    function (e) {
      const nowTouch = new Date().getTime()
      if (nowTouch - endTouch <= 300) {
        // 为什么是300，看注意
        e.preventDefault()
      }
      endTouch = nowTouch
    },
    false
  )
}
const setRemUnit = () => {
  const docEl = document.documentElement
  const width = docEl.clientWidth
  const rem = (width < 350 ? 350 : width > 750 ? 750 : width) / 7.5
  // const rem = docEl.clientWidth / 7.5
  docEl.style.fontSize = `${rem}px`
}
const pageShowCallback = e => {
  if (e.persisted) {
    setRemUnit()
  }
}
document.addEventListener('DOMContentLoaded', setRemUnit, false)
window.addEventListener('resize', setRemUnit, false)
window.addEventListener('pageshow', pageShowCallback, false)
export default setRemUnit
