<template>
  <div class="box">
    <h1
      style="
        font-size: 0.34rem;
        text-align: center;
        margin: 0.28rem 0rem;
        font-weight: 550;
      "
    >
      IM用户协议
    </h1>
    <p class="p2">一、基本条款</p>
    <!-- <p class="p1">&nbsp;1.1</p> -->
    <p class="p1">
      &nbsp;1.1、本协议为xxxxxx有限公司（以下称“我司”）提供的社交平台服务（下称“Chat
      IM”）与其使用人（下称“用户”）之间的服务协议（下称“本协议”）。
    </p>
    <p class="p1">
      &nbsp;1.2、我司在此特别提示，用户使用Chat
      IM服务前，须事先认真阅读本协议全部条款）。
    </p>
    <p class="p1">
      &nbsp;1.3、用户下载、注册、登录、使用及连接Chat
      IM服务等行为均将被视为用户完全了解、接受并同意遵守本协议项下的全部内容。
    </p>
    <p class="p2">二、用户信息条款</p>
    <p class="p1">
      &nbsp;2.1、
      我司尊重授权用户的合法权利，尊重授权用户的自由选择权，不会以违反法律、行政法规以及本协议约定的方式收集、使用用户信息，仅会在用户授权情况下，以信息受保护方式，向第三方提供用户信息。Chat
      IM竭尽全力保护用户隐私信息，配合本《Chat IM用户服务协议》对Chat
      IM用户信息的获取、授权、使用做了更加明确的说明与解释。此政策为Chat
      IM用户协议不可分割的一部分。
    </p>
    <p class="p1">
      &nbsp;2.2、特别说明：用户信息删除的选择权。Chat
      IM用户在使用过程中发现有任何不妥或者不满意之处，可直接向app提交注销账号。
    </p>
    <p class="p2">三、Chat IM服务使用规则</p>
    <p class="p1">
      &nbsp;3.1、用户完全了解并同意：用户对以其帐号登录、使用Chat
      IM服务的全部行为，包括其提供给我司全部用户信息、以其Chat
      IM服务帐号进行的所有行为和事件独立承担完全的法律责任。
    </p>
    <p class="p1">
      &nbsp;3.2、用户同意在使用Chat IM服务过程中，严格遵守以下规则：
    </p>
    <p class="p3">&nbsp;（1）遵守国家法律、法规、行政规章及规范性文件；</p>
    <p class="p3">
      &nbsp;（2）遵守所有与Chat
      IM服务有关的协议、规定、程序、通知、使用守则等全部文件；
    </p>
    <p class="p3">&nbsp;（3）不得为任何违法、犯罪目的而使用Chat IM服务；</p>
    <p class="p3">
      &nbsp;（4）不得以任何形式使用Chat IM服务侵犯Chat IM及其运营方的合法权利；
    </p>
    <p class="p3">
      &nbsp;（5）不得利用Chat
      IM服务进行任何可能对互联网正常运转造成不利影响的行为；
    </p>
    <p class="p3">
      &nbsp;（6）不得利用Chat
      IM提供的服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、种族歧视性的、辱骂性的、恐吓性的、情色的或其他任何非法的信息资料；
    </p>
    <p class="p3">
      &nbsp;（7）不得以任何方式侵犯其他任何人依法享有的专利权、著作权、商标权、商业秘密等知识产权，第三人的名誉权或其他任何第三方的合法权益；
    </p>
    <p class="p3">
      &nbsp;（8）保证在Chat
      IM服务上展示的相关信息内容，包括但是不限于在前条“二、用户信息条款”中的内容，没有违反相关法律规定，没有侵犯第三方的合法权利。
    </p>
    <p class="p1">
      &nbsp;3.3、我司有权对用户使用Chat
      IM服务的情况进行审查和监督，如用户在使用Chat
      IM服务时违反任何上述约定，我司有权要求用户改正或直接单方面采取一切必要措施，以消除或减轻用户不当行为给我司、第三方、互联网服务或社会公共利益造成的任何影响。我司在进行前述操作前，操作过程中或操作完成后不对用户进行任何方式的通知。
    </p>
    <p class="p1">
      &nbsp;3.4、如发生下列任何一种情形，Chat
      IM运营方有权随时中断或终止向用户提供本协议项下的Chat IM服务
      而无需对用户或任何第三方承担任何责任，由此造成的损失由用户自行独立承担：
    </p>
    <p class="p3">&nbsp;3.4.1、用户提供的个人资料不真实；</p>
    <p class="p3">
      &nbsp;3.4.2、用户违反法律法规国家政策或本协议中规定的使用规则；
    </p>
    <p class="p3">
      &nbsp;3.4.3、用户在使用收费服务时未按规定为其所使用的收费服务支付相关服务费用；
    </p>
    <p class="p3">
      &nbsp;3.4.4、用户侵犯个人、企业事业单位或社会团体的合法权益，包括但不限于专利权、著作权、商标权，或姓名权、名称权、名誉权、荣誉权、肖像权、隐私权等；
    </p>
    <p class="p3">
      &nbsp;3.4.5、用户损害监管部门、国家机关及政府形象；用户以任何方式损害Chat
      IM运营方及其关联公司的商誉或信誉等合法权；
    </p>
    <p class="p3">
      &nbsp;3.4.6、Chat IM运营方有其他合理理由认为需要中断或终止向用户提供Chat
      IM服务的。
    </p>
    <p class="p1">
      &nbsp;3.5、用户选择将Chat IM帐号与Chat
      IM运营方合作的第三方帐号进行绑定的，除用户自行解除绑定关系外，如发生下列任何一种情形，用户已绑定的第三方帐号也有可能被解除绑定，Chat
      IM运营方无需对用户或任何第三方承担任何责任：
    </p>
    <p class="p3">&nbsp;3.5.1 、用户违反法律法规国家政策、本协议的；</p>
    <p class="p3">&nbsp;3.5.2 、用户违反第三方用户协议或其相关规定的；</p>
    <p class="p3">&nbsp;3.5.3 、第三方帐号所属的站方要求解除绑定的；</p>
    <p class="p3">&nbsp;3.5.4 、第三方帐号所属平台或业务已关停的；</p>
    <p class="p3">&nbsp;3.5.5 、依据法律法规国家政策规定或主管部门要求的；</p>
    <p class="p3">&nbsp;3.5.6 、Chat IM运营方有合理理由认为需要解除绑定的。</p>
    <p class="p3">
      &nbsp;3.6、用户同意Chat IM运营方在提供Chat
      IM服务过程中以各种方式投放各种商业性广告或其他任何类型的商业信息（包括但不限于在Chat
      IM平台网站的任何页面上投放广告）。
    </p>
    <p class="p3">
      &nbsp;3.7、用户同意，我司有权随时变更、中止或终止部分或全部的Chat
      IM服务，我司无需就此提前通知用户，且我司不因此对用户或任何第三方承担任何责任。
    </p>
    <p class="p2">四、知识产权</p>
    <p class="p1">
      &nbsp;4.1、“知识产权”是指与Chat
      IM服务相关的各种类的过去有效的、现行有效的、或即将产生的知识产权，包括但不限于发明专利、商标、著作权、实用新型、外观设计、布图设计、商业秘密、其他知识产权以及相关申请的权利。
    </p>
    <p class="p1">
      &nbsp;4.2、我司为与Chat IM服务相关的全部知识产权的权利人，对Chat
      IM服务提供过程中包含的全部知识产权，包括但不限于任何文本、图片、音频等享有及保留完整独立的权利。
    </p>
    <p class="p1">
      &nbsp;4.3、本条款非经我司书面同意将持续有效，不因用户关闭Chat
      IM服务帐户或者停止使用Chat IM服务而失效。
    </p>
    <p class="p2">五、其他免责声明</p>
    <p class="p1">
      &nbsp;5.1、信息依现状提供。我司提供的全部服务信息仅依照该等信息提供时的现状提供并仅供用户参考，我司不对前述信息的准确性、完整性、适用性等做任何承诺和保证。用户应对我司提供的信息自行判断，并承担因使用前述信息而引起的任何、全部风险，包括因其对信息的正确性、完整性或实用性的任何依赖或信任而导致的风险。我司无需对因用户使用信息的任何行为导致的任何损失承担责任。
    </p>
    <p class="p1">
      &nbsp;5.2、对于因不可抗力或我司不能预料、或不能控制的原因（包括但不限于计算机病毒或黑客攻击、系统不稳定、用户不当使用帐户、以及其他任何技术、互联网络、通信线路原因等）造成的包括但不限于用户计算机信息和数据的安全，用户个人信息的安全等给用户或任何第三方造成的任何损失等，我司不承担任何责任。
    </p>
    <p class="p1">
      &nbsp;5.3、由于用户以违法、违规或违反本协议约定等任何方式使用Chat
      IM服务的行为，包括但不限于登录网站内容违法、不真实、不正当，侵犯第三方任何合法权益等，给我司或其他第三人造成的任何损失，用户同意承担由此造成的全部损害赔偿责任。
    </p>
    <p class="p1">
      &nbsp;5.4、我司及其合作方对Chat
      IM服务的任何及全部内容不作任何形式的承诺或保证，不论是明确的或暗示的。前述承诺或保证包括但不限于：Chat
      IM服务真实性、适销性、对特定用途的适用性、任何形式的所有权保证，非侵权保证等。我司对因前述未做承诺或保证的内容导致的任何直接、间接、偶然、特殊及后续的损害，不承担任何责任。
    </p>
    <p class="p2">六、国际使用之特别警告</p>
    <p class="p1">
      &nbsp;6.1、您已了解国际互联网的无国界性，同意遵守当地所有关于网上行为及内容之法律法规。您特别同意遵守有关您所在国家或地区输出信息之传输的所有适用法律法规
    </p>
    <p class="p2">七、其他规定</p>
    <p class="p1">
      &nbsp;7.1、Chat
      IM运营方有权随时修改本协议的任何条款，一旦本协议的内容发生变动，Chat
      IM运营方将会在Chat IM平台上公布修改之后的协议内容，Chat
      IM运营方也可选择通过其他适当方式（比如系统通知）向用户通知修改内容。
    </p>
    <p class="p1">
      &nbsp;7.2、如果不同意Chat
      IM运营方对本协议相关条款所做的修改，用户有权停止使用聊天宝服务。如果用户继续使用Chat
      IM服务，则视为用户接受Chat IM运营方对本协议相关条款所做的修改。
    </p>
    <p class="p1">
      &nbsp;7.3、如本协议中的任何条款因任何原因完全或部分无效或不具有执行力，均不影响本协议其他条款的效力。
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.box {
  height: 100%;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  .p1 {
    font-size: 0.28rem;
    margin: 0.4rem 0rem;
    line-height: 0.48rem;
  }
  .p2 {
    font-size: 0.34rem;
    margin: 0.4rem 0rem;
    line-height: 0.48rem;
  }
  .p3 {
    font-size: 0.28rem;
    margin: 0.1rem 0rem;
    line-height: 0.48rem;
  }
}
</style>
